import { FunctionComponent, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthApiClient } from '../api/auth';
import { DashboardContext } from '../contexts/dashboard';

export const ProtectedRoute: FunctionComponent<{ component: JSX.Element }> = ({ component }) => {
  const { isLoggedIn, setLoggedIn } = useContext(DashboardContext);
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      const api = new AuthApiClient();
      const loggedIn = await api.isLoggedIn();
      if (!loggedIn) return navigate('/login');
      setLoggedIn(true);
    };
    checkToken();
  }, []);

  return isLoggedIn ? component : null;
};
