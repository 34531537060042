import { useContext, useState } from 'react';
import { Button, Input, Typography, styled, Container, Box } from '@mui/material';
import { DashboardContext } from '../contexts/dashboard';

const StyledContainer = styled(Container)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Form = styled(Box)`
  width: 400px;
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const Login = () => {
  const dashboardContext = useContext(DashboardContext);

  const [error, setError] = useState<boolean>();
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmitForm = async () => {
    if (loading || !password) {
      return;
    }
    setError(false);
    setLoading(true);
    try {
      await dashboardContext.login({ password });
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
    return false;
  };

  return (
    <StyledContainer>
      <Form id="dashboard-login">
        <Typography variant="h6" component="h6">
          Marketing System Dashboard
        </Typography>
        <Input
          placeholder="Password"
          type="password"
          id="password"
          value={password}
          error={error}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={(e) => {
            if (e.code === 'Enter') {
              onSubmitForm();
            }
          }}
        />
        <Button type={'button'} variant={'contained'} disabled={loading} onClick={onSubmitForm}>
          Login
        </Button>
      </Form>
    </StyledContainer>
  );
}