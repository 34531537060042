import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Product } from '../../constants/product';
import { RobotsError } from '../../dto/robots/error';
import Cookies from 'js-cookie';

export function getBaseUrl(robot: Product): string {
  const host = window.location.host;
  if (host.includes('localhost')) return 'http://localhost:3002/v1';

  if (robot === Product.OLYMP_BOT) {
    return 'https://api.olymp-robot.com/v3';
  }

  if (robot === Product.IQ_BOT) {
    return 'https://api.iqbot.com/v2';
  }

  if (robot === Product.EXPERT_BOT) {
    return 'https://api.expertoptionbot.com/v2';
  }

  if (robot === Product.BINOMO_BOT) {
    return 'https://api.binomobot.com/v1';
  }

  return 'http://localhost:3002/v1';
}

export class ApiError extends Error {
  constructor(message?: string) {
    super(message);
  }

  override toString(): string {
    return this.message;
  }
}

const http = (robot: Product) => axios.create({
  baseURL: getBaseUrl(robot),
  headers: {
    'x-api-key': Cookies.get('apiKey') || '',
  }
});

const getResponse = <T>(response: AxiosResponse<T | RobotsError>): T | RobotsError => {
  const data = response.data;
  if (!data) throw new ApiError('Error');
  if (typeof data === 'object' && 'status' in data && data.status === 'error') {
    if ('message' in data) {
      throw new ApiError(data.message);
    }
    throw new ApiError('Error');
  }
  return response.data;
};

const wrapError = (error: AxiosError): never => {
  if (!error.response) throw error;
  const { data } = error.response;
  // @ts-ignore
  throw new ApiError(data?.message);
};

export abstract class RobotsApiClient {
  private robot;

  constructor(robot: Product) {
    this.robot = robot;
  }

  protected get<TReq>(url: string, options?: AxiosRequestConfig): Promise<TReq> {
    return http(this.robot).get(url, options).then(getResponse, wrapError);
  }

  protected post<TReq, TRes>(url: string, data?: TReq, options?: AxiosRequestConfig): Promise<TRes> {
    return http(this.robot).post(url, data, options).then(getResponse, wrapError);
  }

  protected put<TReq, TRes>(url: string, data?: TReq, options?: AxiosRequestConfig): Promise<TRes> {
    return http(this.robot).put(url, data, options).then(getResponse, wrapError);
  }

  protected delete<TRes>(url: string, options?: AxiosRequestConfig): Promise<TRes> {
    return http(this.robot).delete(url, options).then(getResponse, wrapError);
  }
}
