import { Box, FormGroup, styled, Typography, Select, MenuItem, TextField } from '@mui/material';
import { useContext, useState } from 'react';
import { robots } from '../constants/product';
import { UserApiClient } from '../api/user';
import { LoadingButton } from '@mui/lab';
import { DashboardContext } from '../contexts/dashboard';

const Wrapper = styled(Box)`
  padding: 40px;
`;

const Form = styled('form')`
  display: grid;
  gap: 20px;
`;

const Group = styled(FormGroup)`
  display: flex;
  gap: 20px;
`;

export const AttributionForm = () => {
  const [product, setProduct] = useState<string>(robots[0].value);
  const [traderId, setTraderId] = useState<string>('');
  const [offerId, setOfferId] = useState<string>('');
  const [affiliateId, setAffiliateId] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const { showError, showSuccessMessage } = useContext(DashboardContext);

  const onFormSubmit = async () => {
    const userApi = new UserApiClient();
    setLoading(true);
    try {
      await userApi.updateAttributionForScaleo({ product, traderId, offerId, affiliateId });
      showSuccessMessage(`Атрибуция пользователя ${traderId} успешно обновлена`);
      setTraderId('');
      setOfferId('');
      setAffiliateId('');
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  return (
    <Wrapper>
      <Typography variant={'h5'} mb={2}>Замена атрибуции по trader_id</Typography>
      <Form>
        <Group row={true}>
          <Select
            labelId='product-label'
            id='product-select'
            value={product}
            onChange={(e) => setProduct(e.target.value)}
          >
            {robots.map((p) => (
              <MenuItem value={p.value}>{p.name}</MenuItem>
            ))}
          </Select>
        </Group>
        <Group row={true}>
          <TextField
            required
            label='Trader ID'
            value={traderId}
            onChange={(e) => setTraderId(e.target.value)}
          />
        </Group>
        <Group row={true}>
          <TextField
            required
            label='Offer ID'
            value={offerId}
            onChange={(e) => setOfferId(e.target.value)}
          />
        </Group>
        <Group row={true}>
          <TextField
            required
            label='Affiliate ID'
            value={affiliateId}
            onChange={(e) => setAffiliateId(e.target.value)}
          />
        </Group>
        <LoadingButton
          loading={loading}
          disabled={!product || !traderId || !offerId || !affiliateId}
          onClick={onFormSubmit}
          variant='contained'
          sx={{'width': 'fit-content'}}
        >
          заменить
        </LoadingButton>
      </Form>
    </Wrapper>
  );
};
