import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Login } from '../pages/login';
import { DashboardProvider } from '../contexts/dashboard';
import { ProtectedRoute } from './protectedRoute';
import { Affibots } from '../pages/affibots';
import { NotFound } from '../pages/notFound';

export default () => (
  <BrowserRouter>
    <DashboardProvider>
      <Routes>
        <Route path='/' element={<ProtectedRoute component={<Affibots/>} />} />
        <Route path='/login' element={<Login/>} />
        <Route path='*' element={<NotFound/>} />
      </Routes>
    </DashboardProvider>
  </BrowserRouter>
)