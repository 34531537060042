import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthApiClient } from '../api/auth';
import { AdminLoginReqDto } from '../dto/auth';
import { Alert } from '../components/alert';

enum MessageType {
  SUCCESS = 'success',
  ERROR = 'error',
}

interface Message {
  text: string;
  type: MessageType;
  visible: boolean;
}

interface DashboardContext {
  login: (data: AdminLoginReqDto) => Promise<void>;
  isLoggedIn: boolean;
  setLoggedIn: (s: boolean) => void;
  showError: (error: unknown) => void;
  showSuccessMessage: (message: string) => void;
}

export const DashboardContext = createContext<DashboardContext>({} as DashboardContext);

export const DashboardProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [message, setMessage] = useState<Message>({
    text: '',
    type: MessageType.SUCCESS,
    visible: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (message.visible) {
      setTimeout(() => setMessage((prevState) => ({
        ...prevState,
        visible: false,
      })), 5000);
    }
  }, [message.visible]);

  const login = async ({ password }: AdminLoginReqDto) => {
    const api = new AuthApiClient();
    await api.login({ password });
    setLoggedIn(true);
    navigate('/');
  };

  const showMessage = (text: string, type: MessageType) => {
    setMessage({
      text,
      type,
      visible: true,
    });
  };

  const showSuccessMessage = (text: string) => {
    showMessage(text, MessageType.SUCCESS);
  };

  const showError = (newError: any) => {
    showMessage(String(newError), MessageType.ERROR);
  };

  return (
    <DashboardContext.Provider value={{ login, isLoggedIn, setLoggedIn, showError, showSuccessMessage }}>
      <Alert
        isVisible={message.visible}
        severity={message.type}
        title={message.type}
        text={message.text}
      />
      {children}
    </DashboardContext.Provider>
  );
};
