import { ApiClient } from './base';
import Cookies from 'js-cookie';
import { AdminLoginReqDto, RobotsApiKeyResDto, TokenResDto } from '../dto/auth';

export class AuthApiClient extends ApiClient {
  async login({ password }: AdminLoginReqDto): Promise<void> {
    const { token } = await this.post<AdminLoginReqDto, TokenResDto>(`/auth/admin`, { password });
    Cookies.set('token', token, { expires: 3, path: '' });
    const { apiKey } = await this.get<RobotsApiKeyResDto>(`/auth/robots-api-key`);
    Cookies.set('apiKey', apiKey, { expires: 3, path: '' });
  }

  async isLoggedIn(): Promise<boolean> {
    return !!Cookies.get('token') && !!Cookies.get('apiKey');
  }
}
