export enum Product {
  TIKMUSIC = 'tikmusic',
  EXPERT_BOT = 'expertbot',
  IQ_BOT = 'iqbot',
  OLYMP_BOT = 'olympbot',
  BINOMO_BOT = 'binomobot',
  SEMA = 'sema',
}

export const robots = [
  {
    name: 'Olymp Trade',
    value: Product.OLYMP_BOT,
  },
  // {
  //   name: 'IQ Option',
  //   value: Product.IQ_BOT,
  // },
  // {
  //   name: 'ExpertOption',
  //   value: Product.EXPERT_BOT,
  // },
  // {
  //   name: 'Binomo',
  //   value: Product.BINOMO_BOT,
  // },
];
