import { Header } from '../components/header';
import { AttributionForm } from '../components/attributionForm';
import { Divider } from '@mui/material';
import { ActionType, ActivationManagementForm } from '../components/activationManagementForm';

export const Affibots = () => {
  return (
    <>
      <Header/>
      <AttributionForm/>
      <Divider/>
      <ActivationManagementForm action={ActionType.ACTIVATION}/>
      <Divider/>
      <ActivationManagementForm action={ActionType.DEACTIVATION}/>
    </>
  );
}