import { Box, styled, Typography, Select, MenuItem, TextField } from '@mui/material';
import { useContext, useState } from 'react';
import { Product, robots } from '../constants/product';
import { LoadingButton } from '@mui/lab';
import { RobotsUserApiClient } from '../api/robots/user';
import { DashboardContext } from '../contexts/dashboard';

const Wrapper = styled(Box)`
  padding: 40px;
`;

const Form = styled('form')`
  display: flex;
  gap: 20px;
`;

export enum ActionType {
  ACTIVATION = 'ACTIVATION',
  DEACTIVATION = 'DEACTIVATION',
}

interface Props {
  action: ActionType;
}

export const ActivationManagementForm = ({ action }: Props) => {
  const [product, setProduct] = useState<Product>(robots[0].value);
  const [traderId, setTraderId] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const { showError, showSuccessMessage } = useContext(DashboardContext);

  const onFormSubmit = async () => {
    if (!product || !traderId) return;
    if (!traderId.match(/^\d+$/)) {
      showError('Trader ID должен состоять только из цифр');
      return;
    }
    const robotsUserApi = new RobotsUserApiClient(product);
    setLoading(true);
    try {
      if (action === ActionType.ACTIVATION) {
        await robotsUserApi.createActiveUser({ user_id: traderId });
      }
      if (action === ActionType.DEACTIVATION) {
        await robotsUserApi.deleteUser({ user_id: traderId });
      }
      showSuccessMessage(
        `Пользователь ${traderId} успешно ${action === ActionType.ACTIVATION ? 'активирован' : 'деактивирован'}`,
      );
      setTraderId('');
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  const header = `${action === ActionType.ACTIVATION ? 'Активация' : 'Деактивация'} аккаунта по trader_id`;

  return (
    <Wrapper>
      <Typography variant={'h5'} mb={2}>
        {header}
      </Typography>
      <Form>
        <Select
          labelId="product-label"
          id="product-select"
          value={product}
          onChange={(e) => setProduct(e.target.value as Product)}
        >
          {robots.map((p) => (
            <MenuItem value={p.value}>{p.name}</MenuItem>
          ))}
        </Select>
        <TextField required label="Trader ID" value={traderId} onChange={(e) => setTraderId(e.target.value)} />
        <LoadingButton
          loading={loading}
          disabled={!product || !traderId}
          onClick={onFormSubmit}
          variant="contained"
          sx={{ width: 'fit-content' }}
        >
          {action === ActionType.ACTIVATION ? 'активировать' : 'деактивировать'}
        </LoadingButton>
      </Form>
    </Wrapper>
  );
};
