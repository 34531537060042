import {
  AppBar,
  Box,
  styled,
  Toolbar,
  Typography,
} from '@mui/material';
import { NavLink } from 'react-router-dom';

const StyledNavLink = styled(Box)`
  display: block;
  padding: 8px 22px;
  color: #fff;
`;

const navLinks = [
  {
    to: '/',
    name: 'Affibots',
  }
];

export const Header = () => {
  return (
    <AppBar position={'static'}>
      <Box>
        <Toolbar>
          {navLinks.map((link) => (
            <NavLink to={link.to} key={link.name}>
              <StyledNavLink>
                <Typography variant={'h6'}>{link.name}</Typography>
              </StyledNavLink>
            </NavLink>
          ))}
        </Toolbar>
      </Box>
    </AppBar>
  );
}
