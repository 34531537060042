import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import Cookies from 'js-cookie';

export function getBaseUrl(): string {
  const host = window.location.host;

  if (host.includes('localhost')) return 'http://localhost:3000';

  return 'https://marketing.work.ppnet.io';
}

export class ApiError extends Error {
  constructor(message?: string) {
    super(message);
  }

  override toString(): string {
    return this.message;
  }
}

const http = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use((req) => {
  req.headers['x-access-token'] = Cookies.get('token') ?? '';
  return req;
});

const getResponse = <T>(response: AxiosResponse<T>): T => response.data;

const wrapError = (error: AxiosError): never => {
  if (!error.response) throw error;
  const { data } = error.response;
  // @ts-ignore
  throw new ApiError(data?.message);
};

export abstract class ApiClient {
  protected get<TReq>(url: string, options?: AxiosRequestConfig): Promise<TReq> {
    return http.get(url, options).then(getResponse, wrapError);
  }

  protected post<TReq, TRes>(url: string, data?: TReq, options?: AxiosRequestConfig): Promise<TRes> {
    return http.post(url, data, options).then(getResponse, wrapError);
  }

  protected put<TReq, TRes>(url: string, data?: TReq, options?: AxiosRequestConfig): Promise<TRes> {
    return http.put(url, data, options).then(getResponse, wrapError);
  }

  protected delete<TRes>(url: string, options?: AxiosRequestConfig): Promise<TRes> {
    return http.delete(url, options).then(getResponse, wrapError);
  }
}
