import { RobotsApiClient } from './base';
import { CreateActiveUserReq, DeleteUserReq } from '../../dto/robots/user';
import { RobotsError } from '../../dto/robots/error';

export class RobotsUserApiClient extends RobotsApiClient {
  async createActiveUser(data: CreateActiveUserReq) {
    await this.put<CreateActiveUserReq, RobotsError>('/user/create-pseudo-user', data);
  }

  async deleteUser(data: DeleteUserReq) {
    await this.put<DeleteUserReq, RobotsError>('/user/delete-pseudo-user', data);
  }
}
