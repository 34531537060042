import React from 'react';
import { Alert as MUIAlert, styled, AlertTitle } from '@mui/material';
import { AlertColor } from '@mui/material/Alert/Alert';

interface ContainerProps {
  isVisible: boolean;
}

const AlertContainer = styled(MUIAlert)<ContainerProps>`
  min-width: 250px;
  position: fixed;
  z-index: 2000;
  bottom: 30px;
  right: 30px;
  opacity: ${({ isVisible }) => (isVisible ? '100%' : '0')};
  transition: opacity 0.3s ease-in-out;
`;

interface AlertProps extends ContainerProps {
  title: string;
  text: string;
  severity?: AlertColor;
}

export const Alert = ({ isVisible, title, text, severity }: AlertProps) => {
  return (
    <AlertContainer isVisible={isVisible} severity={severity} variant="filled">
      <AlertTitle>{title}</AlertTitle>
      {text}
    </AlertContainer>
  );
}
